import React from 'react';

const FilterButton = ({ toggleSidebar }) => {
	return (
		<button
			onClick={toggleSidebar}
			className="text-sm fixed bottom-4 right-4 bg-gray-900 text-white px-4 py-2 rounded-full shadow-lg md:hidden z-30"
		>
			絞り込む
		</button>
	);
};

export default FilterButton; 