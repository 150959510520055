import React, { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';

const BookItem = ({ book, onClick }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [imageHeight, setImageHeight] = useState(200);

	const handleImageLoad = (event) => {
		setIsLoading(false);
		const aspectRatio = event.target.naturalWidth / event.target.naturalHeight;
		const width = event.target.width;
		setImageHeight(width / aspectRatio);
	};

	return (
		<button onClick={onClick} className="text-left w-full">
			<div className="relative">
				{isLoading && (
					<Skeleton
						variant="rectangular"
						width="100%"
						height={imageHeight}
						className="mb-2"
					/>
				)}
				<img
					src={book.image}
					alt={book.title}
					className={`w-full mb-2 shadow-lg transition-opacity duration-300 ${isLoading ? 'opacity-0' : 'opacity-100'
						}`}
					onLoad={handleImageLoad}
				/>
			</div>
			<div>
				<h3 className="text-xs mb-1">{book.title}</h3>
			</div>
		</button>
	);
};

export default BookItem;