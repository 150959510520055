import React from 'react';
import { useIntl } from 'react-intl';
import Logo from '../_common/Logo';

const SideBar = ({ availableTags, selectedTags, handleTagSelect }) => {
	const intl = useIntl();
	const locale = intl.locale;

	// タグをソートする関数
	const sortTags = (tags) => {
		return tags.sort((a, b) => {
			const labelA = getTagLabel(a);
			const labelB = getTagLabel(b);
			if (locale === 'ja') {
				return labelA.localeCompare(labelB, 'ja', { sensitivity: 'base' });
			} else {
				return labelA.localeCompare(labelB, 'en');
			}
		});
	};

	const getTagLabel = (tag) => {
		return tag === 'all'
			? intl.formatMessage({ id: 'tag.all' })
			: intl.formatMessage({ id: `tag.${tag.toLowerCase()}` });
	};

	const sortedTags = ['all', ...sortTags(availableTags)];

	return (
		<div className="w-48 h-full overflow-y-auto scrollbar-hide p-2 bg-slate-50 border-r border-gray-200">
			<div className="py-5 justify-center mb-2 hidden md:flex">
				<Logo size="pc" />
			</div>
			<div className="grid gap-1">
				{sortedTags.map(tag => (
					<button
						key={tag}
						onClick={() => handleTagSelect(tag)}
						className={`transition-all duration-300 text-left px-4 py-2 text-xs whitespace-nowrap rounded-md ${(tag === 'all' && selectedTags.length === 0) || selectedTags.includes(tag)
							? ' text-primary bg-slate-200 hover:bg-slate-300'
							: ' text-primary hover:bg-slate-200'
							}`}
					>
						{getTagLabel(tag)}
					</button>
				))}
			</div>
		</div>
	);
};

export default SideBar;