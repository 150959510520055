const messages = {
	'books.count': 'books are introduced!',
	'sort.newest': 'Newest First',
	'sort.oldest': 'Oldest First',
	'view.list': 'List View',
	'view.grid': 'Grid View',
	'search.placeholder': 'Search books by title',
	'search.noResults': 'No books found.',
	'search.tryOther': 'Please try different search criteria.',
	'button.clearFilters': 'Clear Filters',
	'nav.about': 'About',
	'tag.design': 'Design',
	'tag.branding': 'Branding',
	'tag.interaction': 'Interaction',
	'tag.review': 'Review',
	'tag.typography': 'Typography',
	'tag.social': 'Social',
	'tag.graphic': 'Graphic',
	'tag.grid': 'Grid',
	'tag.ux': 'UX',
	'tag.ui': 'UI',
	'tag.research': 'Research',
	'tag.method': 'Method',
	'tag.theory': 'Theory',
	'tag.design-system': 'Design System',
	'tag.facilitation': 'Facilitation',
	'tag.workshop': 'Workshop',
	'tag.web': 'Web',
	'tag.dtp': 'DTP',
	'tag.layout': 'Layout',
	'tag.psychology': 'Psychology',
	'tag.service': 'Service Design',
	'tag.infographic': 'Infographic',
	'tag.storytelling': 'Storytelling',
	'tag.advertising': 'Advertising',
	'tag.color': 'Color',
	'tag.ia': 'Information Architecture',
	'tag.font': 'Font',
	'tag.economy': 'Economy',
	'tag.logo': 'Logo',
	'tag.figma': 'Figma',
	'tag.packaging': 'Packaging',
	'tag.accessibility': 'Accessibility',
	'tag.prototyping': 'Prototyping',
	'tag.system-thinking': 'System Thinking',
	'tag.ai': 'AI',
	'tag.all': 'All',
};

export default messages; 