import { useState, useEffect } from 'react';

const fetchWithNoCache = async (url, options = {}) => {
	// 開発環境でのみ、no-corsモードを使用
	if (process.env.NODE_ENV === 'development') {
		options = {
			...options,
			mode: 'cors',
			credentials: 'include',
			headers: {
				...options.headers,
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache'
			}
		};
	}
	return fetch(url, options);
};

// 日付の形式を整形する関数
const formatDate = (dateString) => {
	if (!dateString) return '';

	// 日付が "YYYY-MM-DD" 形式の場合はそのまま返す
	if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
		return dateString;
	}

	try {
		const date = new Date(dateString);
		if (isNaN(date.getTime())) return ''; // 無効な日付の場合は空文字を返す

		// YYYY-MM-DD形式に変換
		return date.toISOString().split('T')[0];
	} catch (e) {
		console.error('日付の変換に失敗:', dateString);
		return '';
	}
};

export const useBooks = () => {
	const [books, setBooks] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchBooks = async () => {
			try {
				const response = await fetchWithNoCache('/api/v1/books', {
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
				});

				if (!response.ok) {
					let errorMessage = 'データの取得に失敗しました';
					try {
						const errorData = await response.json();
						if (errorData && errorData.message) {
							errorMessage = errorData.message;
						}
					} catch (e) {
						console.error('エラーレスポンスの解析に失敗:', e);
					}
					throw new Error(errorMessage);
				}

				const data = await response.json();
				const formattedBooks = data.map(book => ({
					...book,
					image: book.image_url || book.image,
					amazonUrl: book.amazon_url || book.amazonUrl,
					publishedDate: formatDate(book.published_date || book.publishedDate),
					tags: Array.isArray(book.tags) ? book.tags : (book.tags ? [book.tags] : [])
				}));
				setBooks(formattedBooks);
			} catch (err) {
				console.error('エラー:', err);
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};

		fetchBooks();
	}, []);

	return { books, loading, error };
}; 