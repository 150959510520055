import React from 'react';
import { useBooks } from '../../hooks/useBooks';
import { useIntl } from 'react-intl';

const Logo = ({ size = 'sp' }) => {
	const intl = useIntl();
	const { books } = useBooks();
	const sizeClass = size === 'sp' ? 'text-4xl' : 'text-2xl';

	return (
		<h1 className={sizeClass + ' font-caprasimo leading-none'}>
			<a href="/" className="flex flex-col gap-1">
				<span>Design</span>
				<span>Book</span>
				<span>Collection</span>
			</a>
		</h1>
	);
};

export default Logo;