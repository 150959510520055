import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import jaMessages from './i18n/messages/ja';
import enMessages from './i18n/messages/en';
import About from './components/About';
import { HelmetProvider } from 'react-helmet-async';
const messages = {
	ja: jaMessages,
	en: enMessages
};

function App() {
	const [locale, setLocale] = useState('ja');

	// URLのパスから言語を設定
	useEffect(() => {
		const path = window.location.pathname;
		if (path.startsWith('/en')) {
			setLocale('en');
		} else {
			setLocale('ja');
		}
	}, []);

	const handleLanguageChange = (newLocale) => {
		setLocale(newLocale);
		const currentPath = window.location.pathname;
		const pathSegments = currentPath.split('/').filter(Boolean);

		// タグが存在する場合は、言語切り替え後もタグを保持
		if (pathSegments.length > 0 && pathSegments[0] !== 'en' && pathSegments[0] !== 'about') {
			const newPath = newLocale === 'en' ? `/en/${pathSegments[0]}` : `/${pathSegments[0]}`;
			window.history.pushState({}, '', newPath);
		} else {
			const newPath = newLocale === 'en' ? '/en' : '/';
			window.history.pushState({}, '', newPath);
		}
	};

	return (
		<HelmetProvider>
			<Router>
				<IntlProvider messages={messages[locale]} locale={locale}>
					<div className="h-screen flex flex-col">
						<div className="flex-1 overflow-hidden">
							<Routes>
								<Route path="/" element={<Main onLanguageChange={handleLanguageChange} currentLocale={locale} />} />
								<Route path="/en" element={<Main onLanguageChange={handleLanguageChange} currentLocale={locale} />} />
								<Route path="/:tag" element={<Main onLanguageChange={handleLanguageChange} currentLocale={locale} />} />
								<Route path="/en/:tag" element={<Main onLanguageChange={handleLanguageChange} currentLocale={locale} />} />
								<Route path="/about" element={<About />} />
								<Route path="/en/about" element={<About />} />
								<Route path="*" element={<Navigate to="/" />} />
							</Routes>
						</div>
					</div>
				</IntlProvider>
			</Router>
		</HelmetProvider>
	);
}

export default App;
