import React, { useState, useEffect } from 'react';
import { useBooks } from '../../hooks/useBooks';
import SortDropdown from './SortDropdown';
import BookGrid from './BookGrid';
import SearchBar from './SearchBar';
import ViewToggle from './ViewToggle';
import BookList from './BookList';
import { useIntl } from 'react-intl';
import NotFoundBook from './NotFoundBook';
import SideBar from './SideBar';
import Footer from '../Footer';
import { useParams, useNavigate } from 'react-router-dom';
import jaMessages from '../../i18n/messages/ja';
import enMessages from '../../i18n/messages/en';
import { Helmet } from 'react-helmet-async';
import Logo from '../_common/Logo';
import FilterButton from './FilterButton';
import LanguageSwitcher from '../_common/LanguageSwitcher';

const messages = {
	ja: jaMessages,
	en: enMessages
};

const Main = ({ onLanguageChange, currentLocale }) => {
	const { tag } = useParams();
	const navigate = useNavigate();
	const intl = useIntl();
	const { books, loading, error } = useBooks();
	const [isGridView, setIsGridView] = useState(true);
	const [sortedBooks, setSortedBooks] = useState([]);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [sortOption, setSortOption] = useState(
		intl.formatMessage({ id: 'sort.newest' })
	);
	const [searchTerm, setSearchTerm] = useState('');
	const [selectedTags, setSelectedTags] = useState([]);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	useEffect(() => {
		if (books.length > 0) {
			const initialSortedBooks = [...books].sort((a, b) => {
				return new Date(b.publishedDate) - new Date(a.publishedDate);
			});
			setSortedBooks(initialSortedBooks);
		}
	}, [books]);

	useEffect(() => {
		if (tag) {
			setSelectedTags([tag]);
			const filtered = filterBooks(books, searchTerm, [tag]);
			const sorted = sortByDate(filtered, sortOption === intl.formatMessage({ id: 'sort.newest' }) ? '新しい' : '古い');
			setSortedBooks(sorted);
		}
	}, [tag, books]);

	const filterBooks = (books, searchTerm, tags) => {
		return books.filter(book => {
			const matchesSearch = book.title.toLowerCase().includes(searchTerm.toLowerCase());
			const matchesTags = tags.length === 0 || (tags.length === 1 && book.tags && book.tags.includes(tags[0]));
			return matchesSearch && matchesTags;
		});
	};

	const sortByDate = (books, order) => {
		return [...books].sort((a, b) => {
			return order === '新しい'
				? new Date(b.publishedDate) - new Date(a.publishedDate)
				: new Date(a.publishedDate) - new Date(b.publishedDate);
		});
	};

	const handleSortOptionChange = (option) => {
		setSortOption(option);
		setIsDropdownOpen(false);
		const filtered = filterBooks(books, searchTerm, selectedTags);
		const sorted = sortByDate(filtered, option === intl.formatMessage({ id: 'sort.newest' }) ? '新しい' : '古い');
		setSortedBooks(sorted);
	};

	const handleSearchChange = (event) => {
		const value = event.target.value;
		setSearchTerm(value);
		const filtered = filterBooks(books, value, selectedTags);
		const sorted = sortByDate(filtered, sortOption === intl.formatMessage({ id: 'sort.newest' }) ? '新しい' : '古い');
		setSortedBooks(sorted);
	};

	const handleTagSelect = (selectedTag) => {
		const newTags = selectedTag === 'all' ? [] : selectedTags[0] === selectedTag ? [] : [selectedTag];
		setSelectedTags(newTags);

		if (window.innerWidth < 768) {
			setIsSidebarOpen(false);
		}

		const locale = intl.locale;
		const prefix = locale === 'en' ? '/en' : '';

		if (newTags.length > 0) {
			const tagKey = Object.keys(messages[locale]).find(
				key => key.startsWith('tag.') &&
					intl.formatMessage({ id: key }) === intl.formatMessage({ id: `tag.${selectedTag.toLowerCase()}` })
			);
			const tagPath = tagKey ? tagKey.replace('tag.', '') : selectedTag.toLowerCase();
			navigate(`${prefix}/${tagPath}`);
		} else {
			navigate(prefix || '/');
		}

		const filtered = filterBooks(books, searchTerm, newTags);
		const sorted = sortByDate(filtered, sortOption === intl.formatMessage({ id: 'sort.newest' }) ? '新しい' : '古い');
		setSortedBooks(sorted);
	};

	const clearFilters = () => {
		setSearchTerm('');
		setSelectedTags([]);

		const locale = intl.locale;
		const prefix = locale === 'en' ? '/en' : '';

		navigate(prefix || '/');
		setSortedBooks([...books].sort((a, b) => {
			return new Date(b.publishedDate) - new Date(a.publishedDate);
		}));
	};

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	const getCanonicalUrl = () => {
		const baseUrl = 'https://design-book-collection.com';
		if (tag) {
			return `${baseUrl}/${tag}`;
		}
		return baseUrl;
	};

	const handleLanguageChange = (locale) => {
		onLanguageChange(locale);
	};

	if (loading) {
		return <div className="flex justify-center items-center h-screen">読み込み中...</div>;
	}

	if (error) {
		return <div className="flex justify-center items-center h-screen text-red-500">{error}</div>;
	}

	const availableTags = [...new Set(books.flatMap(book => book.tags || []))];

	return (
		<>
			<Helmet>
				<link rel="canonical" href={getCanonicalUrl()} />
			</Helmet>
			<div className="h-full flex relative">
				<aside className={`fixed top-0 md:relative h-full transition-transform duration-300 bg-white ${isSidebarOpen ? 'h-screen translate-x-0 z-20' : '-translate-x-full md:translate-x-0'
					}`}>
					<SideBar
						availableTags={availableTags}
						selectedTags={selectedTags}
						handleTagSelect={handleTagSelect}
					/>
				</aside>

				{isSidebarOpen && (
					<div
						className="fixed inset-0 bg-black bg-opacity-50 z-10 md:hidden"
						onClick={toggleSidebar}
					/>
				)}

				<div className="flex-1 overflow-y-auto scrollbar-hide">
					<div className="min-h-full flex flex-col">
						<main className="flex-1 p-6">
							<div className="flex md:hidden p-2 justify-center items-center mb-5">
								<Logo size="sp" />
							</div>
							<div className="relative z-[5] flex flex-col md:flex-row justify-between mb-5">
								<SearchBar searchTerm={searchTerm} handleSearchChange={handleSearchChange} />
								<div className="flex justify-end items-center gap-4">
									<ViewToggle isGridView={isGridView} setIsGridView={setIsGridView} />
									<SortDropdown
										isDropdownOpen={isDropdownOpen}
										toggleDropdown={() => setIsDropdownOpen(!isDropdownOpen)}
										sortOption={sortOption}
										handleSortOptionChange={handleSortOptionChange}
									/>
									<LanguageSwitcher onLanguageChange={handleLanguageChange} currentLocale={currentLocale} />
								</div>
							</div>

							<section id="book-list">
								{sortedBooks.length > 0 ? (
									isGridView ? (
										<BookGrid books={sortedBooks} />
									) : (
										<BookList books={sortedBooks} />
									)
								) : (
									<NotFoundBook clearFilters={clearFilters} />
								)}
							</section>
						</main>
						<Footer />
					</div>
				</div>

				<FilterButton toggleSidebar={toggleSidebar} />
			</div>
		</>
	);
};

export default Main;
